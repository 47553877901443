@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-100.eot");
  src: url("../assets/fonts/rawline/rawline-100.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-100.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-100.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-100i.eot");
  src: url("../assets/fonts/rawline/rawline-100i.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-100i.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-100i.ttf") format("truetype");
  font-style: italic;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-200.eot");
  src: url("../assets/fonts/rawline/rawline-200.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-200.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-200.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-200i.eot");
  src: url("../assets/fonts/rawline/rawline-200i.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-200i.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-200i.ttf") format("truetype");
  font-style: italic;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-300.eot");
  src: url("../assets/fonts/rawline/rawline-300.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-300.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-300.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-300i.eot");
  src: url("../assets/fonts/rawline/rawline-300i.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-300i.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-300i.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-400.eot");
  src: url("../assets/fonts/rawline/rawline-400.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-400.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-400.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-400i.eot");
  src: url("../assets/fonts/rawline/rawline-400i.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-400i.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-400i.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-500.eot");
  src: url("../assets/fonts/rawline/rawline-500.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-500.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-500.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-500i.eot");
  src: url("../assets/fonts/rawline/rawline-500i.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-500i.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-500i.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-600.eot");
  src: url("../assets/fonts/rawline/rawline-600.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-600.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-600.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-600i.eot");
  src: url("../assets/fonts/rawline/rawline-600i.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-600i.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-600i.ttf") format("truetype");
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-700.eot");
  src: url("../assets/fonts/rawline/rawline-700.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-700.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-700.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-700i.eot");
  src: url("../assets/fonts/rawline/rawline-700i.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-700i.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-700i.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-800.eot");
  src: url("../assets/fonts/rawline/rawline-800.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-800.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-800.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-800i.eot");
  src: url("../assets/fonts/rawline/rawline-800i.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-800i.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-800i.ttf") format("truetype");
  font-style: italic;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-900.eot");
  src: url("../assets/fonts/rawline/rawline-900.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-900.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-900.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Rawline";
  src: url("../assets/fonts/rawline/rawline-900i.eot");
  src: url("../assets/fonts/rawline/rawline-900i.eot?")
      format("embedded-opentype"),
    url("../assets/fonts/rawline/rawline-900i.woff") format("woff"),
    url("../assets/fonts/rawline/rawline-900i.ttf") format("truetype");
  font-style: italic;
  font-weight: 900;
  font-display: swap;
}
