@import url(./rawline.css);

* {
  font-family: Rawline, sans-serif;
  color: inherit;
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: inherit;
}

strong {
  font-weight: bold;
}

html {
  position: relative;
  height: 100%;
  box-sizing: border-box;
}

body {
  min-width: 100%;
  min-height: 100vh;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

main {
  flex: 1 0 auto;
  overflow: hidden;
}
